import { useNavigate } from 'react-router-dom';
import Logo from '../molecules/Logo';
import styles from './About.module.scss';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { analyticsEvent } from '../../functions';

const About = () => {
  const navigate = useNavigate()

  const backToMenu = () => {
    navigate('/');
    analyticsEvent('click', 'back to menu', 'about');
  }

  return (
    <div>
      <button onClick={backToMenu} className='back-to-menu'>Menu</button>
      <div className={styles['logo-container']}>
        <Logo type='small' />
      </div>
      <div className={styles['text-container']}>
        <h1>{t("about.title")}</h1>
        <Trans
            i18nKey="about.text"
            components={{ p: <p />, strong: <strong /> }}
          />
      </div>
    </div>
  )
}

export default About
