export const throwTypes = {
  ONES: 'ones',
  TWOS: 'twos',
  THREES: 'threes',
  FOURS: 'fours',
  FIVES: 'fives',
  SIXES: 'sixes',
  THREE_OF_A_KIND: 'three-of-a-kind',
  FOUR_OF_A_KIND: 'four-of-a-kind',
  FULL_HOUSE: 'full-house',
  SMALL_STREET: 'small-street',
  LARGE_STREET: 'large-street',
  FIVER: 'fiver',
  CHANCE: 'chance',
  BONUS_FIVER: 'bonus_fiver'
}

export const throwTypeValues = {
  ONES: 1,
  TWOS: 2,
  THREES: 3,
  FOURS: 4,
  FIVES: 5,
  SIXES: 6,
  FULL_HOUSE: 25,
  SMALL_STREET: 30,
  LARGE_STREET: 40,
  FIVER: 50,
  BONUS_FIVER: 100
}

export const chance = {
  LUCKY: 'lucky',
  UNLUCKY: 'unlucky'
}

export const MINIMUM_SCORE_TOP_BONUS = 63;
export const SCORE_TOP_BONUS = 35;


export const APPLICATION_LANGUAGES = {
  ENGLISH: 'English',
  DUTCH: 'Nederlands'
}

export const APPLICATION_LANGUAGE_CODES = {
  ENGLISH: 'en',
  DUTCH: 'nl'
}

export const ACCEPT_COOKIES = 'accept_cookies';
