import { createSlice } from '@reduxjs/toolkit';

export const currentPlayer = createSlice({
  name: 'currentPlayer',
  initialState: {
    value: false
  },
  reducers: {
    setPlayer: (state, action) => {
      if (action.payload !== state.value) {
        state.value = action.payload
      }
    }
  }
})

export const { setPlayer } = currentPlayer.actions

export default currentPlayer.reducer
