import { useState } from 'react';
import Dice1ExtraSmall from '../../../assets/dice-1-extra-small.svg';
import Dice2ExtraSmall from '../../../assets/dice-2-extra-small.svg';
import Dice3ExtraSmall from '../../../assets/dice-3-extra-small.svg';
import Dice4ExtraSmall from '../../../assets/dice-4-extra-small.svg';
import Dice5ExtraSmall from '../../../assets/dice-5-extra-small.svg';
import Dice6ExtraSmall from '../../../assets/dice-6-extra-small.svg';

import _ from 'lodash';
import { MINIMUM_SCORE_TOP_BONUS, SCORE_TOP_BONUS, throwTypeValues, throwTypes } from '../../constants';
import { useSelector } from 'react-redux';
import { getChance, getTotalBottom, getTotalFourOfAKind, getTotalThreeOfAKind, getTotalTop, isFiver, isFullHouse, isLargeStreet, isSmallStreet } from '../../functions';
import { t } from 'i18next';

const GameTable = ({ onAddValues }) => {

  const players = useSelector(state => state.players.value);
  const settings = useSelector(state => state.settings.value)

  const [showTotals] = useState(_.has(settings, 'showTotals') && settings.showTotals === 'Yes' ? true : false);

  return (
    <table className='game-table'>
      <tbody>
        <tr>
          <td className='has-dice'><img src={Dice1ExtraSmall} alt={`${t("dice")} 1`} />{t("game-table.count all")} <strong>{t("game-table.ones")}</strong></td>
          {players.map((player, index) => <td key={`1-${index}`} className={_.has(player, throwTypes.ONES) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.ONES)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.ONES) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.ONES) ? Object.values(player[throwTypes.ONES]).filter(value => value === 1).length : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='has-dice'><img src={Dice2ExtraSmall} alt={`${t("dice")} 2`} />{t("game-table.count all")} <strong>{t("game-table.twos")}</strong></td>
          {players.map((player, index) => <td key={`2-${index}`} className={_.has(player, throwTypes.TWOS) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.TWOS)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.TWOS) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.TWOS) ? Object.values(player[throwTypes.TWOS]).filter(value => value === 2).length * 2 : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='has-dice'><img src={Dice3ExtraSmall} alt={`${t("dice")} 3`} />{t("game-table.count all")} <strong>{t("game-table.threes")}</strong></td>
          {players.map((player, index) => <td key={`3-${index}`} className={_.has(player, throwTypes.THREES) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.THREES)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.THREES) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.THREES) ? Object.values(player[throwTypes.THREES]).filter(value => value === 3).length * 3 : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='has-dice'><img src={Dice4ExtraSmall} alt={`${t("dice")} 4`} />{t("game-table.count all")} <strong>{t("game-table.fours")}</strong></td>
          {players.map((player, index) => <td key={`4-${index}`} className={_.has(player, throwTypes.FOURS) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.FOURS)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.FOURS) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.FOURS) ? Object.values(player[throwTypes.FOURS]).filter(value => value === 4).length * 4 : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='has-dice'><img src={Dice5ExtraSmall} alt={`${t("dice")} 5`} />{t("game-table.count all")} <strong>{t("game-table.fives")}</strong></td>
          {players.map((player, index) => <td key={`5-${index}`} className={_.has(player, throwTypes.FIVES) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.FIVES)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.FIVES) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.FIVES) ? Object.values(player[throwTypes.FIVES]).filter(value => value === 5).length * 5 : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='has-dice'><img src={Dice6ExtraSmall} alt={`${t("dice")} 6`} />{t("game-table.count all")} <strong>{t("game-table.sixes")}</strong></td>
          {players.map((player, index) => <td key={`6-${index}`} className={_.has(player, throwTypes.SIXES) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.SIXES)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.SIXES) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.SIXES) ? Object.values(player[throwTypes.SIXES]).filter(value => value === 6).length * 6 : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        {showTotals && <tr>
          <td className='text-right'><strong>{t("game-table.total")}</strong></td>
          {players.map((player, index) => <td key={`7-${index}`} className={`player-color-${index + 1}`}><strong>{getTotalTop(players, index)}</strong></td>)}
        </tr>}
        {showTotals && <tr>
          <td className='text-left-right'>{MINIMUM_SCORE_TOP_BONUS} {t("game-table.or more")}<strong>{t("game-table.bonus")} {SCORE_TOP_BONUS}</strong></td>
          {players.map((player, index) => <td key={`8-${index}`} className={`player-color-${index + 1}`}><strong>{getTotalTop(players, index) >= MINIMUM_SCORE_TOP_BONUS ? SCORE_TOP_BONUS : 0}</strong></td>)}
        </tr>}
        {showTotals && <tr>
          <td className='text-right'><strong>{t("game-table.total top")}</strong></td>
          {players.map((player, index) => <td key={`9-${index}`} className={`player-color-${index + 1}`}><strong>{getTotalTop(players, index) >= MINIMUM_SCORE_TOP_BONUS ? getTotalTop(players, index) + SCORE_TOP_BONUS : getTotalTop(players, index)}</strong></td>)}
        </tr>}
        <tr>
          <td className='text-left-right'>{t("game-table.three of a kind")}<strong>{t("game-table.total")}</strong></td>
          {players.map((player, index) => <td key={`10-${index}`} className={_.has(player, throwTypes.THREE_OF_A_KIND) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.THREE_OF_A_KIND)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.THREE_OF_A_KIND) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.THREE_OF_A_KIND) ? getTotalThreeOfAKind(players, index) : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='text-left-right'>{t("game-table.four of a kind")}<strong>{t("game-table.total")}</strong></td>
          {players.map((player, index) => <td key={`11-${index}`} className={_.has(player, throwTypes.FOUR_OF_A_KIND) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.FOUR_OF_A_KIND)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.FOUR_OF_A_KIND) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.FOUR_OF_A_KIND) ? getTotalFourOfAKind(players, index) : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='text-left-right'>{t("game-table.full house")}<strong>{throwTypeValues.FULL_HOUSE}</strong></td>
          {players.map((player, index) => <td key={`12-${index}`} className={_.has(player, throwTypes.FULL_HOUSE) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.FULL_HOUSE)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.FULL_HOUSE) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.FULL_HOUSE) ? (isFullHouse(players, index) ? throwTypeValues.FULL_HOUSE : '0') : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='text-left-right'>{t("game-table.small street")}<strong>{throwTypeValues.SMALL_STREET}</strong></td>
          {players.map((player, index) => <td key={`13-${index}`} className={_.has(player, throwTypes.SMALL_STREET) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.SMALL_STREET)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.SMALL_STREET) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.SMALL_STREET) ? (isSmallStreet(players, index) ? throwTypeValues.SMALL_STREET : '0') : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='text-left-right'>{t("game-table.large street")}<strong>{throwTypeValues.LARGE_STREET}</strong></td>
          {players.map((player, index) => <td key={`14-${index}`} className={_.has(player, throwTypes.LARGE_STREET) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.LARGE_STREET)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.LARGE_STREET) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.LARGE_STREET) ? (isLargeStreet(players, index) ? throwTypeValues.LARGE_STREET : '0') : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='text-left-right'><strong>{t("game-table.fiver")}</strong><strong>{throwTypeValues.FIVER}</strong></td>
          {players.map((player, index) => <td key={`15-${index}`} className={_.has(player, throwTypes.FIVER) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.FIVER)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.FIVER) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.FIVER) ? (isFiver(players, index) ? throwTypeValues.FIVER : '0') : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='text-left-right'>{t("game-table.chance")}<strong>{t("game-table.total")}</strong></td>
          {players.map((player, index) => <td key={`16-${index}`} className={_.has(player, throwTypes.CHANCE) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.CHANCE)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.CHANCE) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.CHANCE) ? getChance(players, index) : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        <tr>
          <td className='text-left-right'><strong>{t("game-table.bonus fiver")}</strong><strong>{throwTypeValues.BONUS_FIVER}</strong></td>
          {players.map((player, index) => <td key={`17-${index}`} className={_.has(player, throwTypes.BONUS_FIVER) ? '' : 'has-action'}>
            <button onClick={() => onAddValues(index, throwTypes.BONUS_FIVER)} className={`add-values-button player-color-${index + 1} ${!_.has(player, throwTypes.BONUS_FIVER) ? 'add-pointer' : ''}`}>
              {_.has(player, throwTypes.BONUS_FIVER) ? (isFiver(players, index) && isFiver(players, index, true) ? throwTypeValues.BONUS_FIVER : '0') : t('game-table.add')}
            </button>
          </td>)}
        </tr>
        {showTotals && <tr>
          <td className='text-right'><strong>{t("game-table.total bottom")}</strong></td>
          {players.map((player, index) => <td key={`18-${index}`} className={`player-color-${index + 1}`}><strong>{getTotalBottom(players, index)}</strong></td>)}
        </tr>}
        {showTotals && <tr>
          <td className='text-right'><strong>{t("game-table.total top")}</strong></td>
          {players.map((player, index) => <td key={`19-${index}`} className={`player-color-${index + 1}`}><strong>{getTotalTop(players, index) >= MINIMUM_SCORE_TOP_BONUS ? getTotalTop(players, index) + SCORE_TOP_BONUS : getTotalTop(players, index)}</strong></td>)}
        </tr>}
        {showTotals && <tr>
          <td className='text-right'><strong>{t("game-table.total top + bottom")}</strong></td>
          {players.map((player, index) => <td key={`20-${index}`} className={`player-color-${index + 1}`}><strong>{getTotalTop(players, index) >= MINIMUM_SCORE_TOP_BONUS ? getTotalTop(players, index) + SCORE_TOP_BONUS + getTotalBottom(players, index) : getTotalTop(players, index) + getTotalBottom(players, index)}</strong></td>)}
        </tr>}
      </tbody>
    </table>
  )
}

export default GameTable;
