import { createSlice } from '@reduxjs/toolkit';

export const currentThrow = createSlice({
  name: 'currentThrow',
  initialState: {
    value: false
  },
  reducers: {
    setThrow: (state, action) => {
      if (action.payload !== state.value) {
        state.value = action.payload
      }
    }
  }
})

export const { setThrow } = currentThrow.actions

export default currentThrow.reducer
