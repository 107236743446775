const Toggle = ({ name, checked = false, onChange, label }) => {
  return (
    <div className="toggle">
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
      />
      <label htmlFor={name}>
        <span className='switch-container'>
          <span className="switch" />
        </span>
        {label}
      </label>
    </div>
  )
}

export default Toggle;
