import { configureStore } from '@reduxjs/toolkit'
import currentPlayerReducer from './reducers/currentPlayer'
import currentThrowReducer from './reducers/currentThrow';
import playersReducer from './reducers/players';
import settingsReducer from './reducers/settings';

const preloadedState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')) : {}
const store = configureStore({
  reducer: {
    currentPlayer: currentPlayerReducer,
    currentThrow: currentThrowReducer,
    players: playersReducer,
    settings: settingsReducer
  },
  preloadedState: preloadedState
})

store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})

export default store
