import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const players = createSlice({
  name: 'players',
  initialState: {
    value: []
  },
  reducers: {
    addPlayer: state => {
      state.value = [...state.value, {}]
    },
    removePlayer: (state, action) => {
      state.value = [...state.value].filter((players, index) => index !== action.payload - 1)
    },
    setPlayers: (state, action) => {
      if (action.payload !== state.value) {
        state.value = action.payload
      }
    },
    setThrow: (state, action) => {
      if (_.has(action.payload, 'player') && _.has(action.payload, 'throwName') && _.has(action.payload, 'throw') && state.value[action.payload.player]) {
        const tmpValue = [...state.value];
        tmpValue[action.payload.player][action.payload.throwName] = action.payload.throw;
        state.value = tmpValue;
      }
    }
  }
})

export const { addPlayer, removePlayer, setPlayers, setThrow } = players.actions

export default players.reducer
