import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styles from './Game.module.scss';
import GameTable from '../organisms/GameTable';
import Single from '../organisms/modals/throws/Single';
import { throwTypes } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayer } from '../../reducers/currentPlayer';
import { setThrow } from '../../reducers/currentThrow';
import { addPlayer } from '../../reducers/players';
import DeletePlayerModal from '../organisms/modals/DeletePlayer';
import EndOfGameModal from '../organisms/modals/EndOfGame';
import ThreeOfAKind from '../organisms/modals/throws/ThreeOfAKind';
import FourOfAKind from '../organisms/modals/throws/FourOfAKind';
import FullHouse from '../organisms/modals/throws/FullHouse';
import Fiver from '../organisms/modals/throws/Fiver';
import Chance from '../organisms/modals/throws/Chance';
import SmallStreet from '../organisms/modals/throws/SmallStreet';
import LargeStreet from '../organisms/modals/throws/LargeStreet';
import Logo from '../molecules/Logo';
import { t } from 'i18next';
import { analyticsEvent, isFinished } from '../../functions';

const Game = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const players = useSelector(state => state.players.value);

  const [showSingleValueModal, setShowSingleValueModal] = useState(false);
  const [showThreeOfAKindModal, setShowThreeOfAKindModal] = useState(false);
  const [showFourOfAKindModal, setShowFourOfAKindModal] = useState(false);
  const [showFullHouseModal, setShowFullHouseModal] = useState(false);
  const [showSmallStreetModal, setShowSmallStreetModal] = useState(false);
  const [showLargeStreetModal, setShowLargeStreetModal] = useState(false);
  const [showFiverModal, setShowFiverModal] = useState(false);
  const [showChanceModal, setShowChanceModal] = useState(false);
  const [showBonusFiverModal, setShowBonusFiverModal] = useState(false);

  const [showDeletePlayerModal, setShowDeletePlayerModal] = useState(false);
  const [showEndOfGameModal, setShowEndOfGameModal] = useState(false);
  const [finished, setFinished] = useState(false);

  const onAddPlayer = () => {
    dispatch(addPlayer());
    analyticsEvent('game', 'add', 'player');
  }

  const onDeletePlayer = playerIndex => {
    dispatch(setPlayer(playerIndex));
    analyticsEvent('game', 'try to remove', 'player');
    setShowDeletePlayerModal(true);
  }

  const onBackToMenu = () => {
    navigate('/');
    analyticsEvent('click', 'back to menu', 'game');
  }

  const onAddValues = (playerIndex, throwType) => {
    dispatch(setPlayer(playerIndex));
    dispatch(setThrow(throwType));

    switch (throwType) {
      case throwTypes.ONES:
        setShowSingleValueModal(true);
        analyticsEvent('show', 'modal', 'single values', 'ones');
        break;
      case throwTypes.TWOS:
        setShowSingleValueModal(true);
        analyticsEvent('show', 'modal', 'single values', 'twos');
        break;
      case throwTypes.THREES:
        setShowSingleValueModal(true);
        analyticsEvent('show', 'modal', 'single values', 'threes');
        break;
      case throwTypes.FOURS:
        setShowSingleValueModal(true);
        analyticsEvent('show', 'modal', 'single values', 'fours');
        break;
      case throwTypes.FIVES:
        setShowSingleValueModal(true);
        analyticsEvent('show', 'modal', 'single values', 'fives');
        break;
      case throwTypes.SIXES:
        setShowSingleValueModal(true);
        analyticsEvent('show', 'modal', 'single values', 'sixes');
        break;
      case throwTypes.THREE_OF_A_KIND:
        setShowThreeOfAKindModal(true);
        analyticsEvent('show', 'modal', 'three of a kind');
        break;
      case throwTypes.FOUR_OF_A_KIND:
        setShowFourOfAKindModal(true);
        analyticsEvent('show', 'modal', 'four of a kind');
        break;
      case throwTypes.FULL_HOUSE:
        setShowFullHouseModal(true);
        analyticsEvent('show', 'modal', 'full house');
        break;
      case throwTypes.SMALL_STREET:
        setShowSmallStreetModal(true);
        analyticsEvent('show', 'modal', 'small street');
        break;
      case throwTypes.LARGE_STREET:
        setShowLargeStreetModal(true);
        analyticsEvent('show', 'modal', 'large street');
        break;
      case throwTypes.FIVER:
        setShowFiverModal(true);
        analyticsEvent('show', 'modal', 'fiver');
        break;
      case throwTypes.CHANCE:
        setShowChanceModal(true);
        analyticsEvent('show', 'modal', 'chance');
        break;
      case throwTypes.BONUS_FIVER:
        setShowBonusFiverModal(true);
        analyticsEvent('show', 'modal', 'bonus fiver');
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setFinished(isFinished(players));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(players)])

  useEffect(() => {
    if (finished) {
      setShowEndOfGameModal(true);
      analyticsEvent('game', 'game is finished', 'game', true);
    }
  }, [finished])

  useEffect(() => {
    if (players.length === 0) {
      dispatch(addPlayer());
      analyticsEvent('game', 'player', 'add first player', true);
    }
  }, [players, dispatch])

  return (
    <div>
      <button onClick={onBackToMenu} className='back-to-menu'>Menu</button>
      <div className={styles['logo-container']}>
        <Logo type='small' />
      </div>
      {players.length && <div className={`${styles['players-outer-container']} ${players.length < 4 ? styles['add-button-visible'] : ''}`}>
        <div className={styles['players-container']}>
          <ul className={styles['player-number-listing']}>{players.map((player, index) => <li key={index} className={`player-color-${index + 1}`}>{index + 1}</li>)}</ul>
        </div>
      </div>}
      <div className={styles['table-outer-container']}>
        <div className={styles['table-container']}>
          <GameTable players={players} onAddValues={onAddValues} />
          {players.length < 4 && <div className={styles['add-player-button-container']}><button onClick={() => onAddPlayer()} className={`add-player ${styles['add-player']}`}><span>{t("game.add player")}</span></button></div>}
        </div>
      </div>
      {players.length > 1 && <div className={`${styles['buttons-outer-container']} ${players.length < 4 ? styles['add-button-visible'] : ''}`}>
        <div className={styles['buttons-container']}>
          <ul className={styles['delete-buttons']}>{players.map((player, index) => index > 0 && <li key={index}><button onClick={() => onDeletePlayer(index)} className='delete-player'>{t("game.delete player")}</button></li>)}</ul>
        </div>
      </div>}
      {showSingleValueModal && <Single onClose={() => setShowSingleValueModal(false)} />}

      {showDeletePlayerModal && <DeletePlayerModal onClose={() => setShowDeletePlayerModal(false)} />}

      {showEndOfGameModal && <EndOfGameModal onClose={() => setShowEndOfGameModal(false)} />}

      {showThreeOfAKindModal && <ThreeOfAKind onClose={() => setShowThreeOfAKindModal(false)}/>}

      {showFourOfAKindModal && <FourOfAKind onClose={() => setShowFourOfAKindModal(false)}/>}

      {showFullHouseModal && <FullHouse onClose={() => setShowFullHouseModal(false)}/>}

      {showSmallStreetModal && <SmallStreet onClose={() => setShowSmallStreetModal(false)}/>}

      {showLargeStreetModal && <LargeStreet onClose={() => setShowLargeStreetModal(false)}/>}

      {showFiverModal && <Fiver onClose={() => setShowFiverModal(false)}/>}

      {showChanceModal && <Chance onClose={() => setShowChanceModal(false)}/>}

      {showBonusFiverModal && <Fiver onClose={() => setShowBonusFiverModal(false)} bonus={true}/>}
    </div>
  )
}

export default Game
