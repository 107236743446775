import { useDispatch, useSelector } from 'react-redux';
import styles from './SmallStreet.module.scss';
import { useEffect, useRef, useState } from 'react';
import { chance } from '../../../../constants';

import Dice1Big from '../../../../../assets/dice-1-big.svg';
import Dice2Big from '../../../../../assets/dice-2-big.svg';
import Dice3Big from '../../../../../assets/dice-3-big.svg';
import Dice4Big from '../../../../../assets/dice-4-big.svg';
import Dice5Big from '../../../../../assets/dice-5-big.svg';
import Dice6Big from '../../../../../assets/dice-6-big.svg';
import { setThrow } from '../../../../reducers/players';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { analyticsEvent } from '../../../../functions';

const SmallStreet = ({ onClose }) => {

  const modal = useRef()
  const modalContainer = useRef()
  const dispatch = useDispatch()

  const currentPlayer = useSelector(state => state.currentPlayer.value);
  const currentThrow = useSelector(state => state.currentThrow.value);
  const players = useSelector(state => state.players.value);

  const [label] = useState(t("game-table.small street"));

  const [selectedOption, setSelectedOption] = useState(chance.LUCKY);

  const onSaveThrow = () => {
    const throwObject = {
      Dice1: 1,
      Dice2: 2,
      Dice3: 3,
      Dice4: 4,
      Dice5: 6,
    };

    if (selectedOption === chance.UNLUCKY) {
      throwObject.Dice1 = 1;
      throwObject.Dice2 = 2;
      throwObject.Dice3 = 3;
      throwObject.Dice4 = 5;
      throwObject.Dice4 = 6;
    }

    dispatch(setThrow({player: currentPlayer, throwName: currentThrow, throw: throwObject}));
    analyticsEvent('save', 'throw', 'small street', JSON.stringify({ player: currentPlayer, throwName: currentThrow, throw: throwObject }));
    analyticsEvent('close', 'modal', 'small street');
    onClose();
  }

  const onOptionChange = event => {
    setSelectedOption(event.target.value);
  }

  useEffect(() => {
    const container = modalContainer.current
    const isClickedOutside = e => {
      if (modal.current && !modal.current.contains(e.target)) {
        onClose()
      }
    }

    container.addEventListener("click", isClickedOutside)

    return () => {
      container.removeEventListener("click", isClickedOutside)
    }
  }, [onClose])

  useEffect(() => {
    if (players[currentPlayer][currentThrow]) {
      if (
        players[currentPlayer][currentThrow].Dice1 !== 1 ||
        players[currentPlayer][currentThrow].Dice2 !== 2 ||
        players[currentPlayer][currentThrow].Dice3 !== 3 ||
        players[currentPlayer][currentThrow].Dice4 !== 4
        ) {
          setSelectedOption("unlucky");
      }
    }
  }, [players, currentPlayer, currentThrow])

  return (
    <div className={`modal-container throw-modal ${styles['player-color-' + (currentPlayer + 1)]}`} ref={modalContainer}>
      <div className='modal' ref={modal}>
        <div className='modal-content'>
          <button className='modal-close' onClick={onClose}>{t('modal.close')}</button>
          <span className='modal-title'>{t("modal.small street.title {{ label }}", {label})}</span>
          <Trans
            i18nKey="modal.small street.text {{ label }}"
            values={{ label }}
            components={{ p: <p />, strong: <strong /> }}
          />
          <div className={styles['select-dices-container']}>
            <label className={`radio-button ${styles['radio-button']}`}>
              <input type="radio" value={chance.LUCKY} checked={selectedOption === chance.LUCKY} onChange={onOptionChange} />
              <span></span>
              <div className={styles['dices-outer-container']}>
                <span className={styles['throw-category']}>{label}</span>
                <div className={styles['dices-container']}>
                  <ul className={styles['dices']}>
                    <li><strong>#1</strong><img src={Dice1Big} alt={`${t("dice")} 1`} /></li>
                    <li><strong>#2</strong><img src={Dice2Big} alt={`${t("dice")} 2`} /></li>
                    <li><strong>#3</strong><img src={Dice3Big} alt={`${t("dice")} 3`} /></li>
                    <li><strong>#4</strong><img src={Dice4Big} alt={`${t("dice")} 4`} /></li>
                  </ul>
                  <ul className={styles['dices']}>
                    <li><strong>#1</strong><img src={Dice2Big} alt={`${t("dice")} 1`} /></li>
                    <li><strong>#2</strong><img src={Dice3Big} alt={`${t("dice")} 2`} /></li>
                    <li><strong>#3</strong><img src={Dice4Big} alt={`${t("dice")} 3`} /></li>
                    <li><strong>#4</strong><img src={Dice5Big} alt={`${t("dice")} 4`} /></li>
                  </ul>
                  <ul className={styles['dices']}>
                    <li><strong>#1</strong><img src={Dice3Big} alt={`${t("dice")} 1`} /></li>
                    <li><strong>#2</strong><img src={Dice4Big} alt={`${t("dice")} 2`} /></li>
                    <li><strong>#3</strong><img src={Dice5Big} alt={`${t("dice")} 3`} /></li>
                    <li><strong>#4</strong><img src={Dice6Big} alt={`${t("dice")} 4`} /></li>
                  </ul>
                </div>
              </div>
            </label>
            <label className={`radio-button ${styles['radio-button']}`}>
              <input type="radio" value={chance.UNLUCKY} checked={selectedOption === chance.UNLUCKY} onChange={onOptionChange}/>
              <span></span>{t("modal.no luck this time")}
            </label>
          </div>
          <button onClick={() => onSaveThrow()} className={styles['save-throw-button']}><i className="fa-solid fa-floppy-disk"></i>{t('modal.save')}</button>
        </div>
      </div>
    </div>
  )
}

export default SmallStreet;
