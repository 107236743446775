import Home from './components/pages/Home';
import Game from './components/pages/Game';
import Settings from './components/pages/Settings';
import About from './components/pages/About';
import { Link, useLocation, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ACCEPT_COOKIES, APPLICATION_LANGUAGES } from './constants';
import { useEffect, useState } from 'react';
import i18n from './i18n';
import { addGoogleAnalyticsScript, getLanguageCode } from './functions';
import CookiesModal from './components/organisms/modals/Cookies';
import Logo from './components/molecules/Logo';
import styles from './App.module.scss';
import Cookies from './components/pages/Cookies';
import { t } from 'i18next';

const Views = () => {
  return useRoutes([
    {
      path: "/",
      element: <Home />
    }, {
      path: "/game",
      element: <Game />
    }, {
      path: "/settings",
      element: <Settings />
    }, {
      path: "/about",
      element: <About />
    }, {
      path: "/cookies",
      element: <Cookies />
    }
  ])
}

const App = () => {

  const settings = useSelector(state => state.settings.value);
  const location = useLocation();

  const [languages] = useState([
    APPLICATION_LANGUAGES.ENGLISH,
    APPLICATION_LANGUAGES.DUTCH
  ]);
  const [showCookiesModal, setShowCookiesModal] = useState(false);

  useEffect(() => {
    if (languages.find(l => l === settings['language']) && i18n.language !== getLanguageCode(settings['language'])) {
      i18n.changeLanguage(getLanguageCode(settings['language']));
    }
  }, [settings, languages]);

  useEffect(() => {
    if (localStorage.getItem(ACCEPT_COOKIES)) {
      addGoogleAnalyticsScript();
    }
  }, [showCookiesModal])

  useEffect(() => {
    switch (location.pathname) {
      case '/':
      case '/game':
      case '/settings':
      case '/about':
        setShowCookiesModal(!localStorage.getItem(ACCEPT_COOKIES));
        break;
      default:
        setShowCookiesModal(false);
        break;
    }
  }, [location])

  return (
    <div className='view-container'>
      {!showCookiesModal && <Views />}
      {showCookiesModal && <div className={styles['logo-container']}><Logo /></div>}
      {showCookiesModal && <CookiesModal onClose={() => setShowCookiesModal(false)} />}
      <ul className={styles['footer-menu']}>
        <li>{new Date().getFullYear()}</li>
        <li>Made with <i className="fa-solid fa-heart"></i> by <a href="https://whitewave.studio" target="_blank" rel="noreferrer">whitewave.studio</a></li>
        <li><Link to="/cookies" className={styles['cookie-policy-link']}>{t("app.link cookie policy")}</Link></li>
      </ul>
    </div>
  );
}

export default App;
