import { ACCEPT_COOKIES, APPLICATION_LANGUAGES, APPLICATION_LANGUAGE_CODES, throwTypeValues, throwTypes } from './constants'
import _ from 'lodash';

export const getTotalTop = (players, playerIndex) => {
  let total = 0

  if (_.has(players[playerIndex], throwTypes.ONES)) {
    total += Object.values(players[playerIndex][throwTypes.ONES]).filter(value => value === 1).length
  }

  if (_.has(players[playerIndex], throwTypes.TWOS)) {
    total += Object.values(players[playerIndex][throwTypes.TWOS]).filter(value => value === 2).length * 2
  }

  if (_.has(players[playerIndex], throwTypes.THREES)) {
    total += Object.values(players[playerIndex][throwTypes.THREES]).filter(value => value === 3).length * 3
  }

  if (_.has(players[playerIndex], throwTypes.FOURS)) {
    total += Object.values(players[playerIndex][throwTypes.FOURS]).filter(value => value === 4).length * 4
  }

  if (_.has(players[playerIndex], throwTypes.FIVES)) {
    total += Object.values(players[playerIndex][throwTypes.FIVES]).filter(value => value === 5).length * 5
  }

  if (_.has(players[playerIndex], throwTypes.SIXES)) {
    total += Object.values(players[playerIndex][throwTypes.SIXES]).filter(value => value === 6).length * 6
  }

  return total;
}

export const getTotalThreeOfAKind = (players, playerIndex) => {
  let total = 0;

  if (_.has(players[playerIndex], throwTypes.THREE_OF_A_KIND)) {
    const uniqueValues = {}
    Object.values(players[playerIndex][throwTypes.THREE_OF_A_KIND]).forEach(diceValue => { uniqueValues[diceValue] = (uniqueValues[diceValue] || 0) + 1; });
    if (Object.values(uniqueValues).some(value => value >= 3)) {
      Object.values(players[playerIndex][throwTypes.THREE_OF_A_KIND]).forEach(value => total += value);
    }
  }

  return total;
}

export const getTotalFourOfAKind = (players, playerIndex) => {
  let total = 0;

  if (_.has(players[playerIndex], throwTypes.FOUR_OF_A_KIND)) {
    const uniqueValues = {}
    Object.values(players[playerIndex][throwTypes.FOUR_OF_A_KIND]).forEach(diceValue => { uniqueValues[diceValue] = (uniqueValues[diceValue] || 0) + 1; });
    if (Object.values(uniqueValues).some(value => value >= 4)) {
      Object.values(players[playerIndex][throwTypes.FOUR_OF_A_KIND]).forEach(value => total += value);
    }
  }

  return total;
}

export const isFullHouse = (players, playerIndex) => {
  if (_.has(players[playerIndex], throwTypes.FULL_HOUSE)) {
    const uniqueValues = {}
    Object.values(players[playerIndex][throwTypes.FULL_HOUSE]).forEach(diceValue => { uniqueValues[diceValue] = (uniqueValues[diceValue] || 0) + 1; });
    if (
      Object.values(uniqueValues).length === 2 && (
        (Object.values(uniqueValues)[0] === 2 && Object.values(uniqueValues)[1] === 3) ||
        (Object.values(uniqueValues)[1] === 2 && Object.values(uniqueValues)[0] === 3)
      )
    ) {
      return true
    }
  }

  return false
}

export const isSmallStreet = (players, playerIndex) => {
  if (_.has(players[playerIndex], throwTypes.SMALL_STREET)) {
    const values = [...new Set(Object.values(players[playerIndex][throwTypes.SMALL_STREET]))].sort();

    for (let i = 0; i < values.length - 1; i++) {
      if (values[i] + 1 === values[i + 1]) {
        if (i === 2) {
          return true
        }
      }
    }
  }

  return false
}

export const isLargeStreet = (players, playerIndex) => {
  if (_.has(players[playerIndex], throwTypes.LARGE_STREET)) {
    const values = Object.values(players[playerIndex][throwTypes.LARGE_STREET]).sort();

    for (let i = 0; i < values.length - 1; i++) {
      if (values[i] + 1 !== values[i + 1]) {
        return false
      }
    }

    return true
  }

  return false
}

export const isFiver = (players, playerIndex, bonus = false) => {
  let key = throwTypes.FIVER;
  if (bonus) {
    key = throwTypes.BONUS_FIVER
  }

  if (_.has(players[playerIndex], key)) {
    const uniqueValues = {}
    Object.values(players[playerIndex][key]).forEach(diceValue => { uniqueValues[diceValue] = (uniqueValues[diceValue] || 0) + 1; });
    if (
      Object.values(uniqueValues).length === 1 && Object.values(uniqueValues)[0] === 5
    ) {
      return true
    }
  }

  return false
}

export const getChance = (players, playerIndex) => {
  let total = 0;

  if (_.has(players[playerIndex], throwTypes.CHANCE)) {
    Object.values(players[playerIndex][throwTypes.CHANCE]).forEach(value => total += value);
  }

  return total;
}

export const getTotalBottom = (players, playerIndex) => {
  let total = 0;

  if (_.has(players[playerIndex], throwTypes.THREE_OF_A_KIND)) {
    total += getTotalThreeOfAKind(players, playerIndex)
  }

  if (_.has(players[playerIndex], throwTypes.FOUR_OF_A_KIND)) {
    total += getTotalFourOfAKind(players, playerIndex)
  }

  if (_.has(players[playerIndex], throwTypes.FULL_HOUSE)) {
    total += isFullHouse(players, playerIndex) ? throwTypeValues.FULL_HOUSE : 0
  }

  if (_.has(players[playerIndex], throwTypes.SMALL_STREET)) {
    total += isSmallStreet(players, playerIndex) ? throwTypeValues.SMALL_STREET : 0
  }

  if (_.has(players[playerIndex], throwTypes.LARGE_STREET)) {
    total += isLargeStreet(players, playerIndex) ? throwTypeValues.LARGE_STREET : 0
  }

  if (_.has(players[playerIndex], throwTypes.FIVER)) {
    total += isFiver(players, playerIndex) ? throwTypeValues.FIVER : 0
  }

  if (_.has(players[playerIndex], throwTypes.CHANCE)) {
    total += getChance(players, playerIndex)
  }

  if (_.has(players[playerIndex], throwTypes.BONUS_FIVER)) {
    total += isFiver(players, playerIndex) && isFiver(players, playerIndex, true) ? throwTypeValues.BONUS_FIVER : 0
  }

  return total
}
export const getLanguageCode = language => {
  switch (language) {
    case APPLICATION_LANGUAGES.DUTCH:
      return APPLICATION_LANGUAGE_CODES.DUTCH;
    default:
      return APPLICATION_LANGUAGE_CODES.ENGLISH;
  }
}

export const isFinished = players => {
  let finished = true;

  if (players.length > 0) {
    players.forEach(player => {
      if (
        !_.has(player, throwTypes.ONES) ||
        !_.has(player, throwTypes.TWOS) ||
        !_.has(player, throwTypes.THREES) ||
        !_.has(player, throwTypes.FOURS) ||
        !_.has(player, throwTypes.FIVES) ||
        !_.has(player, throwTypes.SIXES) ||
        !_.has(player, throwTypes.THREE_OF_A_KIND) ||
        !_.has(player, throwTypes.FOUR_OF_A_KIND) ||
        !_.has(player, throwTypes.FULL_HOUSE) ||
        !_.has(player, throwTypes.SMALL_STREET) ||
        !_.has(player, throwTypes.LARGE_STREET) ||
        !_.has(player, throwTypes.FIVER) ||
        !_.has(player, throwTypes.CHANCE) ||
        !_.has(player, throwTypes.BONUS_FIVER)
      ) {
        finished = false;
      }
    })
  } else {
    finished = false;
  }

  return finished;
}

export const analyticsEvent = (action, category, label, value, nonInteraction = false) => {
  if (!localStorage.getItem(ACCEPT_COOKIES)) {
    return;
  }

  const eventObject = {};

  if (category) {
    eventObject.category = category;
  }

  if (label) {
    eventObject.label = label;
  }

  if (value) {
    eventObject.value = value;
  }

  if (nonInteraction) {
    eventObject.non_interaction = nonInteraction;
  }

  window.gtag('event', action, eventObject);
}

export const addGoogleAnalyticsScript = () => {
  const addGoogleAnalytics = document.createElement("script");
  addGoogleAnalytics.setAttribute("src","https://www.googletagmanager.com/gtag/js?id=G-GLMQ12ZSKT");
  addGoogleAnalytics.async = "true";
  document.head.appendChild(addGoogleAnalytics);

  const addDataLayer = document.createElement("script");
  const dataLayerData = document.createTextNode(`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-GLMQ12ZSKT');
  `);
  addDataLayer.appendChild(dataLayerData);
  document.head.appendChild(addDataLayer);
}
