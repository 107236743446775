import { useNavigate } from 'react-router-dom'
import styles from './Home.module.scss';
import Logo from '../molecules/Logo';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removePlayer } from '../../reducers/players';
import ConfirmNewGameModal from '../organisms/modals/ConfirmNewGame';
import { t } from 'i18next';
import { analyticsEvent, isFinished } from '../../functions';

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const players = useSelector(state => state.players.value);

  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isPWAInstalled, setIsPWAInstalled] = useState(false);
  const [showConfirmNewGameModal, setShowConfirmNewGameModal] = useState(false);
  const [finished, setFinished] = useState(true);

  const continueGame = () => {
    navigate('/game');
    analyticsEvent('click', 'menu', 'continue game');
  }

  const goToNewGame = () => {
    if (!finished && players.length > 0) {
      setShowConfirmNewGameModal(true);
    } else {
      for (let i = players.length; i > 0; i--) {
        dispatch(removePlayer(i));
      }
      navigate('/game');
      analyticsEvent('click', 'menu', 'new game');
    }
  };

  const goToSettings = () => {
    navigate('/settings');
    analyticsEvent('click', 'menu', 'settings');

  };

  const goToAbout = () => {
    navigate('/about');
    analyticsEvent('click', 'menu', 'about');
  };

  const onClickInstall = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
    analyticsEvent('click', 'menu', 'install as application');
  }

  useEffect(() => {
    setFinished(isFinished(players));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(players)])

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    const checkIfAppIsInstalled = async () => {
      if ("getInstalledRelatedApps" in navigator) {
        const relatedApps = await navigator.getInstalledRelatedApps();
        setIsPWAInstalled(relatedApps.length > 0);
      }
    }

    checkIfAppIsInstalled();

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  return (
    <div className={styles['home-container']}>
      <Logo type='big' />
      <ul className={styles['menu']}>
        {!finished && players.length > 0 && <li><button onClick={continueGame}><i className="fa-solid fa-play"></i>{t('home.menu.continue game')}</button></li>}
        <li><button onClick={goToNewGame}><i className="fa-solid fa-rocket"></i>{t('home.menu.new game')}</button></li>
        <li><button onClick={goToSettings}><i className="fa-solid fa-gears"></i>{t('home.menu.settings')}</button></li>
        <li><button onClick={goToAbout}><i className="fa-solid fa-info-circle"></i>{t('home.menu.about this app')}</button></li>
        {supportsPWA && !isPWAInstalled && <li><button className="install" onClick={onClickInstall}><i className="fa-solid fa-download"></i>{t('home.menu.install this app')}</button></li>}
      </ul>
      { showConfirmNewGameModal && <ConfirmNewGameModal onClose={() => { setShowConfirmNewGameModal(false) }} />}
    </div>
  )
}

export default Home
