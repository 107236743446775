import { useEffect, useRef, useState } from 'react';
import Dice1Big from '../../../../../assets/dice-1-big.svg';
import Dice2Big from '../../../../../assets/dice-2-big.svg';
import Dice3Big from '../../../../../assets/dice-3-big.svg';
import Dice4Big from '../../../../../assets/dice-4-big.svg';
import Dice5Big from '../../../../../assets/dice-5-big.svg';
import Dice6Big from '../../../../../assets/dice-6-big.svg';
import { throwTypeValues, throwTypes } from '../../../../constants';

import styles from './Single.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setThrow } from '../../../../reducers/players';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { analyticsEvent } from '../../../../functions';

const Single = ({ onClose = () => {}}) => {

  const modal = useRef()
  const modalContainer = useRef()
  const dispatch = useDispatch()

  const currentPlayer = useSelector(state => state.currentPlayer.value);
  const currentThrow = useSelector(state => state.currentThrow.value);

  const [dice, setDice] = useState(Dice1Big);
  const [label, setLabel] = useState(t("game-table.ones"));
  const [type, setType] = useState(throwTypes.ones);

  const selectQuantity = amount => {
    const throwObject = {}

    for (let i = 0; i < amount; i++) {
      throwObject[`Dice${i + 1}`] = throwTypeValues[type];
    }

    dispatch(setThrow({player: currentPlayer, throwName: currentThrow, throw: throwObject}));
    analyticsEvent('save', 'throw', 'single values', JSON.stringify({ player: currentPlayer, throwName: currentThrow, throw: throwObject }));
    analyticsEvent('close', 'modal', 'single values', currentThrow);
    onClose();
  }

  const getDice = () => {
    switch (currentThrow) {
      case throwTypes.ONES:
        setDice(Dice1Big);
        setLabel(t("game-table.ones"))
        break;
      case throwTypes.TWOS:
        setDice(Dice2Big);
        setLabel(t("game-table.twos"))
        break;
      case throwTypes.THREES:
        setDice(Dice3Big);
        setLabel(t("game-table.threes"))
        break;
      case throwTypes.FOURS:
        setDice(Dice4Big);
        setLabel(t("game-table.fours"))
        break;
      case throwTypes.FIVES:
        setDice(Dice5Big);
        setLabel(t("game-table.fives"))
        break;
      case throwTypes.SIXES:
        setDice(Dice6Big);
        setLabel(t("game-table.sixes"))
        break;
      default:
        setDice(false)
        setLabel(false)
        break;
    }
  }

  useEffect(() => {
    const container = modalContainer.current
    const isClickedOutside = e => {
      if (modal.current && !modal.current.contains(e.target)) {
        onClose()
      }
    }

    container.addEventListener("click", isClickedOutside)

    return () => {
      container.removeEventListener("click", isClickedOutside)
    }
  }, [onClose])

  useEffect(() => {
    getDice();
    setType(Object.keys(throwTypes).find(key => throwTypes[key] === currentThrow))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentThrow])

  return (
    <div className={`modal-container throw-modal ${styles['player-color-' + (currentPlayer + 1)]}`} ref={modalContainer}>
      <div className='modal' ref={modal}>
        <div className='modal-content'>
          <button className='modal-close' onClick={onClose}>{t('modal.close')}</button>
          <span className='modal-title'>{t('modal.single.title {{ label }}', {label})}</span>
          <Trans
            i18nKey="modal.single.text {{ label }}"
            values={{ label }}
            components={{ p: <p />, strong: <strong /> }}
          />
          <div className={styles['feedback-container']}>
            <div className={styles['dice-container']}>
              <img src={dice} alt='Dice' />
            </div>

            <div className={styles['icon-container']}>
              <i className='fa-solid fa-times'></i>
            </div>

            <ul className={styles['quantity-buttons']}>
              <li><button onClick={() => selectQuantity(0)}>0</button></li>
              <li><button onClick={() => selectQuantity(1)}>1</button></li>
              <li><button onClick={() => selectQuantity(2)}>2</button></li>
              <li><button onClick={() => selectQuantity(3)}>3</button></li>
              <li><button onClick={() => selectQuantity(4)}>4</button></li>
              <li><button onClick={() => selectQuantity(5)}>5</button></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Single
