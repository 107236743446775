import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import Toggle from '../../molecules/Toggle';
import styles from './Cookies.module.scss';
import { useState } from 'react';
import { ACCEPT_COOKIES } from '../../../constants';

const CookiesModal = ({onClose}) => {

  const [checked, setChecked] = useState(localStorage.getItem(ACCEPT_COOKIES) ? true : false);

  const onConfirm = () => {
    if (checked) {
      localStorage.setItem(ACCEPT_COOKIES, checked);
      onClose();
    }
  }

  const onChange = checked => {
    setChecked(checked);
  }

  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='modal-content'>
          <span className='modal-title'>{t("modal.cookies.title")}</span>
          <Trans
            i18nKey="modal.cookies.text"
            components={{ p: <p />, strong: <strong /> }}
          />
          <p><Link to="/cookies">{t("modal.cookies.more info")}</Link></p>
          <div>
            <Toggle name="confirm" checked={checked} onChange={onChange} label={t("modal.cookies.accept")}/>
            <button disabled={!checked} className={styles['confirm-button']} onClick={onConfirm}><i className='fa-solid fa-rocket'></i>{t("modal.cookies.play")}</button>
            {!checked && <span className={styles['message']}>{t("modal.cookies.message")}</span>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookiesModal;
