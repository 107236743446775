import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removePlayer } from '../../../reducers/players';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { analyticsEvent } from '../../../functions';

const ConfirmNewGameModal = ({onClose}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const players = useSelector(state => state.players.value);

  const onContainerClick = event => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      analyticsEvent('close', 'modal', 'new game');
      onClose();
    }
  }

  const onConfirm = () => {
    for (let i = players.length; i > 0; i--) {
      dispatch(removePlayer(i));
    }
    analyticsEvent('game', 'new game', 'confirm');
    analyticsEvent('close', 'modal', 'new game');

    onClose();
    navigate('/game');
  }

  return (
    <div className='modal-container' onClick={event => onContainerClick(event)}>
      <div className='modal'>
        <div className='modal-content'>
          <button className='modal-close' onClick={onClose}>{t('modal.close')}</button>
          <span className='modal-title'>{t("modal.new game.title")}</span>
          <Trans
            i18nKey="modal.new game.text"
            components={{ p: <p />, strong: <strong /> }}
          />
          <ul className='modal-buttons'>
            <li><button onClick={() => onConfirm()}><i className="fa-solid fa-thumbs-up"></i>{t('modal.yes')}</button></li>
            <li><button onClick={() => onClose()}><i className="fa-solid fa-thumbs-down"></i>{t('modal.no')}</button></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ConfirmNewGameModal;
