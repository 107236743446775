import { useNavigate } from 'react-router-dom';
import styles from './EnfOdGame.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addPlayer } from '../../../reducers/players';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { MINIMUM_SCORE_TOP_BONUS, SCORE_TOP_BONUS } from '../../../constants';
import { analyticsEvent, getTotalBottom, getTotalTop } from '../../../functions';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

const EndOfGameModal = ({onClose}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const players = useSelector(state => state.players.value);

  const [scores, setScores] = useState(false);

  const onContainerClick = event => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      analyticsEvent('close', 'modal', 'end of game');
      onClose();
    }
  }

  const onResetGame = () => {
    for (let i = 0; i < players.length; i++) {
      dispatch(addPlayer())
    }
    analyticsEvent('game', 'reset', 'reset after finish');
    analyticsEvent('close', 'modal', 'end of game');
    onClose();
  }

  useEffect(() => {
    let tmpScores = []
      players.forEach((player, index) => {
        tmpScores.push({
          player: index,
          score: getTotalTop(index) >= MINIMUM_SCORE_TOP_BONUS ? getTotalTop(players, index) + SCORE_TOP_BONUS + getTotalBottom(players, index) : getTotalTop(players, index) + getTotalBottom(players, index)
        })
      })
      setScores(_.orderBy([...tmpScores], ['score'], ['desc']))
  }, [players])

  useEffect(() => {
    if (_.first(scores)) {
      analyticsEvent('game', 'winner', 'player', _.first(scores)?.player + 1);
    }
  }, [scores])

  return (
    <div className='modal-container' onClick={event => onContainerClick(event)}>
      <div className='modal'>
        <div className='modal-content'>
          <button className='modal-close' onClick={onClose}>{t('modal.close')}</button>
          <span className='modal-title'>{t("modal.end of game.title")}</span>
          {scores && <>
            <Trans
              i18nKey="modal.end of game.text {{ player }}"
              values={{player: _.first(scores)?.player + 1}}
              components={{ p: <p />, strong: <strong />}}
            />
            <table className={styles['scores-table']}>
              <tbody>
                {scores.map((score, index) => <tr key={index}>
                  <td><strong>{t("modal.player")} {score.player + 1}</strong></td>
                  <td>{score.score}</td>
                </tr>)}
              </tbody>
            </table>
            <ul className={styles['scores-listing']}></ul>
          </>}
          <ul className='modal-buttons'>
            <li><button onClick={() => onResetGame()}><i className="fa-solid fa-thumbs-up"></i>{t('modal.yes')}</button></li>
            <li><button onClick={() => navigate('/')}><i className="fa-solid fa-thumbs-down"></i>{t('modal.no')}</button></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default EndOfGameModal;
