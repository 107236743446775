import { useDispatch, useSelector } from 'react-redux';
import styles from './Fiver.module.scss';
import { useEffect, useRef, useState } from 'react';
import { chance } from '../../../../constants';

import Dice1Big from '../../../../../assets/dice-1-big.svg';
import Dice2Big from '../../../../../assets/dice-2-big.svg';
import Dice3Big from '../../../../../assets/dice-3-big.svg';
import Dice4Big from '../../../../../assets/dice-4-big.svg';
import Dice5Big from '../../../../../assets/dice-5-big.svg';
import Dice6Big from '../../../../../assets/dice-6-big.svg';

import Dice1Default from '../../../../../assets/dice-1-default.svg';
import Dice2Default from '../../../../../assets/dice-2-default.svg';
import Dice3Default from '../../../../../assets/dice-3-default.svg';
import Dice4Default from '../../../../../assets/dice-4-default.svg';
import Dice5Default from '../../../../../assets/dice-5-default.svg';
import Dice6Default from '../../../../../assets/dice-6-default.svg';
import { setThrow } from '../../../../reducers/players';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { analyticsEvent } from '../../../../functions';

const Fiver = ({ onClose, bonus = false }) => {

  const modal = useRef();
  const modalContainer = useRef();
  const dispatch = useDispatch();

  const currentPlayer = useSelector(state => state.currentPlayer.value);
  const currentThrow = useSelector(state => state.currentThrow.value);
  const players = useSelector(state => state.players.value);

  const [label] = useState(t("game-table.fiver"));

  const [selectedOption, setSelectedOption] = useState(chance.LUCKY);

  const [currentDice1, setCurrentDice1] = useState(Dice1Big);
  const [currentDice2, setCurrentDice2] = useState(Dice1Big);
  const [currentDice3, setCurrentDice3] = useState(Dice1Big);
  const [currentDice4, setCurrentDice4] = useState(Dice1Big);
  const [currentDice5, setCurrentDice5] = useState(Dice1Big);

  const [dice1, setDice1] = useState(1);
  const [dice2, setDice2] = useState(1);
  const [dice3, setDice3] = useState(1);
  const [dice4, setDice4] = useState(1);
  const [dice5, setDice5] = useState(1);

  const onSaveThrow = () => {
    const throwObject = {
      Dice1: dice1,
      Dice2: dice2,
      Dice3: dice3,
      Dice4: dice4,
      Dice5: dice5,
    };

    if (selectedOption === chance.UNLUCKY) {
      throwObject.Dice1 = 1;
      throwObject.Dice2 = 2;
      throwObject.Dice3 = 3;
      throwObject.Dice4 = 6;
      throwObject.Dice5 = 5;
    }

    dispatch(setThrow({player: currentPlayer, throwName: currentThrow, throw: throwObject}));
    analyticsEvent('save', 'throw', bonus ? 'bonus fiver' : 'fiver', JSON.stringify({ player: currentPlayer, throwName: currentThrow, throw: throwObject }));
    analyticsEvent('close', 'modal', bonus ? 'bonus fiver' : 'fiver');
    onClose();
  }

  const onOptionChange = event => {
    setSelectedOption(event.target.value);
  }

  const getDice = index => {
    switch (index) {
      case 1:
        return Dice1Big;
      case 2:
        return Dice2Big;
      case 3:
        return Dice3Big;
      case 4:
        return Dice4Big;
      case 5:
        return Dice5Big;
      default:
        return Dice6Big;
    }
  }

  const onSelectDices = diceValue => {
    setCurrentDice1(getDice(diceValue));
    setCurrentDice2(getDice(diceValue));
    setCurrentDice3(getDice(diceValue));
    setCurrentDice4(getDice(diceValue));
    setCurrentDice5(getDice(diceValue));
    setDice1(diceValue);
    setDice2(diceValue);
    setDice3(diceValue);
    setDice4(diceValue);
    setDice5(diceValue);
  }

  useEffect(() => {
    const container = modalContainer.current;
    const isClickedOutside = e => {
      if (modal.current && !modal.current.contains(e.target)) {
        onClose()
      }
    }

    container.addEventListener("click", isClickedOutside)

    return () => {
      container.removeEventListener("click", isClickedOutside)
    }
  }, [onClose])

  useEffect(() => {
    if (players[currentPlayer][currentThrow]) {
      const dices  = [
        players[currentPlayer][currentThrow].Dice1,
        players[currentPlayer][currentThrow].Dice2,
        players[currentPlayer][currentThrow].Dice3,
        players[currentPlayer][currentThrow].Dice4,
        players[currentPlayer][currentThrow].Dice5
      ]
      if (!dices.every(val => val === dices[0])) {
        setSelectedOption(chance.UNLUCKY);
        setCurrentDice1(getDice(1));
        setCurrentDice2(getDice(1));
        setCurrentDice3(getDice(1));
        setCurrentDice4(getDice(1));
        setCurrentDice5(getDice(1));
        setDice1(1);
        setDice2(1);
        setDice3(1);
        setDice4(1);
        setDice5(1);
      } else {
        setCurrentDice1(getDice(players[currentPlayer][currentThrow].Dice1));
        setCurrentDice2(getDice(players[currentPlayer][currentThrow].Dice2));
        setCurrentDice3(getDice(players[currentPlayer][currentThrow].Dice3));
        setCurrentDice4(getDice(players[currentPlayer][currentThrow].Dice4));
        setCurrentDice5(getDice(players[currentPlayer][currentThrow].Dice5));
        setDice1(players[currentPlayer][currentThrow].Dice1);
        setDice2(players[currentPlayer][currentThrow].Dice2);
        setDice3(players[currentPlayer][currentThrow].Dice3);
        setDice4(players[currentPlayer][currentThrow].Dice4);
        setDice5(players[currentPlayer][currentThrow].Dice5);
      }
    }
  }, [players, currentPlayer, currentThrow])

  return (
    <div className={`modal-container throw-modal ${styles['player-color-' + (currentPlayer + 1)]}`} ref={modalContainer}>
      <div className='modal' ref={modal}>
        <div className='modal-content'>
          <button className='modal-close' onClick={onClose}>{t('modal.close')}</button>
          <span className='modal-title'>{ bonus ? t("modal.fiver.another {{ label }}", {label}) : t("modal.fiver.title")}</span>
          <Trans
            i18nKey="modal.fiver.text {{ bonus }} {{ label }}"
            values={{ bonus: bonus ? t("modal.fiver.second") : '', label }}
            components={{ p: <p />, strong: <strong /> }}
          />
          <div className={styles['select-dices-container']}>
            <label className={`radio-button ${styles['radio-button']}`}>
              <input type="radio" value={chance.LUCKY} checked={selectedOption === chance.LUCKY} onChange={onOptionChange} />
              <span></span>
              <div className={styles['dices-container']}>
                <ul className={styles['dices']}>
                  <li>
                    <span className={styles['throw-category']}>{label}</span>
                    <ul className={styles['throw-category-container']}>
                      <li><strong>#1</strong><img src={currentDice1} alt={`${t("dice")} 1`} /></li>
                      <li><strong>#2</strong><img src={currentDice2} alt={`${t("dice")} 2`} /></li>
                      <li className={styles['active']}><strong>#3</strong><img src={currentDice3} alt={`${t("dice")} 3`} /></li>
                      <li><strong>#4</strong><img src={currentDice4} alt={`${t("dice")} 4`} /></li>
                      <li><strong>#5</strong><img src={currentDice5} alt={`${t("dice")} 5`} /></li>
                    </ul>
                  </li>
                </ul>

                <ul className={styles['dice-options']}>
                  <li onClick={() => onSelectDices(1)}><img src={Dice1Default} alt={`${t("dice")} 1`} /></li>
                  <li onClick={() => onSelectDices(2)}><img src={Dice2Default} alt={`${t("dice")} 2`} /></li>
                  <li onClick={() => onSelectDices(3)}><img src={Dice3Default} alt={`${t("dice")} 3`} /></li>
                  <li onClick={() => onSelectDices(4)}><img src={Dice4Default} alt={`${t("dice")} 4`} /></li>
                  <li onClick={() => onSelectDices(5)}><img src={Dice5Default} alt={`${t("dice")} 5`} /></li>
                  <li onClick={() => onSelectDices(6)}><img src={Dice6Default} alt={`${t("dice")} 6`} /></li>
                </ul>
              </div>
            </label>
            <label className={`radio-button ${styles['radio-button']}`}>
              <input type="radio" value={chance.UNLUCKY} checked={selectedOption === chance.UNLUCKY} onChange={onOptionChange}/>
              <span></span>{t("modal.no luck this time")}
            </label>
          </div>
          <button onClick={() => onSaveThrow()} className={styles['save-throw-button']}><i className="fa-solid fa-floppy-disk"></i>{t("modal.save")}</button>
        </div>
      </div>
    </div>
  )
}

export default Fiver;
