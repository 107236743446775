import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      "Yes": "Yes",
      "No": "No",
      "Save": "Save",
      "Saving": "Saving",
      "dice": "Dice",
      "home.menu.continue game": "Continue game",
      "home.menu.new game": "New game",
      "home.menu.settings": "Settings",
      "home.menu.about this app": "About App",
      "home.menu.install this app": "Install this app",
      "about.title": "About this application",
      "settings.option.language": "Language",
      "settings.option.show totals in table": "Show totals in table",
      "about.text": `<p><strong>Fiver</strong> is a "Yahtzee"-alike dice game that combines strategy, luck, and a dash of competition. In this engaging pastime, players take turns rolling five dice with the goal of achieving various combinations and accumulating points.</p><p>Each player gets three rolls on their turn, allowing them to keep some dice and reroll others to optimize their results. The ultimate challenge in <strong>Fiver</strong> is to score a <strong>"Fiver"</strong>, which is when all five dice show the same value. Achieving this is a moment of pure triumph and often comes with a substantial point reward.</p><p>Throughout the game, players aim for combinations like straights, full houses, and four-of-a-kind, each with its own set of points. Strategy comes into play as players decide which category to fill in on their scorecard, trying to maximize their score in each turn.</p>`,
      "game.add player": "Add player",
      "game.delete player": "Delete player",
      "game-table.add": "Add",
      "game-table.total": "Total",
      "game-table.count all": "Count all",
      "game-table.ones": "Ones",
      "game-table.twos": "Twos",
      "game-table.threes": "Threes",
      "game-table.fours": "Fours",
      "game-table.fives": "Fives",
      "game-table.sixes": "Sixes",
      "game-table.or more": "or more",
      "game-table.bonus": "Bonus",
      "game-table.pair": "Pair",
      "game-table.three of a kind": "3 of a kind",
      "game-table.four of a kind": "4 of a kind",
      "game-table.full house": "Full house",
      "game-table.small street": "Small street",
      "game-table.large street": "Large street",
      "game-table.chance": "Chance",
      "game-table.fiver": "Fiver",
      "game-table.bonus fiver": "Bonus Fiver",
      "game-table.total bottom": "Total bottom",
      "game-table.total top": "Total top",
      "game-table.total top + bottom": "Total (top + bottom)",
      "modal.close": "Close",
      "modal.save": "Save",
      "modal.yes": "Yes",
      "modal.no": "No",
      "modal.player": "Player",
      "modal.no luck this time": "No luck this time",
      "modal.chance.title {{ label }}": "Aiming for a {{ label }}?",
      "modal.chance.text {{ label }}": "<p>Select the value for each of the five dice, starting with the <strong>{{ label }}</strong>. The score will be calculated automatically.</p>",
      "modal.four of a kind.title {{ label }}": "Aiming for a {{ label }}?",
      "modal.four of a kind.text {{ label }}": "<p>Select the value for each of the five dice, starting with the <strong>{{ label }}</strong>. The score will be calculated automatically.</p>",
      "modal.full house.title {{ label }}": "You've just rolled a {{ label }}?",
      "modal.full house.text {{ label }}": "<p>Did roll the dice and manage to land a <strong>{{ label }}</strong> combination? With three dice showing one value and two dice showing another, like this?</p>",
      "modal.single.title {{ label }}": "How many {{ label }} did you roll?",
      "modal.single.text {{ label }}": "<p>Select how many <strong>{{ label }}</strong> you've rolled in this throw. The score will be calculated automatically.</p>",
      "modal.small street.title {{ label }}": "Aiming for a {{ label }}?",
      "modal.small street.text {{ label }}": "<p>Which combination of dice did you roll to achieve a <strong>{{ label }}</strong>?</p>",
      "modal.large street.title {{ label }}": "Aiming for a {{ label }}?",
      "modal.large street.text {{ label }}": "<p>Which combination of dice did you roll to achieve a <strong>{{ label }}</strong>?</p>",
      "modal.three of a kind.title {{ label }}": "Aiming for a {{ label }}?",
      "modal.three of a kind.text {{ label }}": "<p>Select the value for each of the five dice, starting with the <strong>{{ label }}</strong>. The score will be calculated automatically.</p>",
      "modal.fiver.second": "second",
      "modal.fiver.another {{ label }}": "Another {{ label }}",
      "modal.fiver.title": "Are you on fire?",
      "modal.fiver.text {{ bonus }} {{ label }}": "<p>Choose the values rolled for your <strong>{{ bonus }}{{ label }}</strong></p>",
      "modal.new game.title": "New game!",
      "modal.new game.text": "<p>We've come across a pre-existing game. Would you like to reset and begin anew?</p>",
      "modal.delete player.title": "Delete player",
      "modal.delete player.text {{ player }}": "<p>Are you sure you want to remove <strong>Player {{ player }}</strong>?</p>",
      "modal.end of game.title": "Game over!",
      "modal.end of game.text {{ player }}": "<p>Congratulations to <strong>Player {{ player }}</strong>! <br/> Would you like to play again?</p>",
      "modal.cookies.title": "Cookies",
      "modal.cookies.text": "<p>We use <strong>Google Analytics</strong> to gain valuable insights into our website's performance and user behavior. This data-driven approach helps us enhance the user experience, optimize content, and make informed decisions to improve our online presence and better serve our audience.</p>",
      "modal.cookies.accept": "I accept cookies",
      "modal.cookies.play": "Let's play",
      "modal.cookies.more info": "Read here more about our cooky policy",
      "cookies.title": "Cookie policy",
      "cookies.text": `
        <p>We use <strong>Google Analytics</strong> to gain valuable insights into our website's performance and user behavior. This data-driven approach helps us enhance the user experience, optimize content, and make informed decisions to improve our online presence and better serve our audience.</p>
        <p><strong>Name: </strong>_ga, _gat, _gid</p>
        <p><strong>Description: </strong>Google Analytics is one of the most common and reliable analytics solutions on the internet, to help gather information about how the website can best be used and how the user experience can be improved. With this cookie, we may keep track of how much time you spend on our website, which pages you visit exactly and how you found us. This way, we know what visitors are interested in and we can continue to improve the website and its content.</p>
        <p><strong>Duration: </strong>This cookie is from Google and will be deleted after a maximum of 2 years.</p>`,
      "modal.cookies.message": "Please accept \"cookies\"!",
      "app.link cookie policy": "Cookie policy"
    }
  },
  nl: {
    translation: {
      "Yes": "Ja",
      "No": "Neen",
      "Save": "Opslaan",
      "Saving": "Bezig met opslaan",
      "dice": "Dobbelsteen",
      "home.menu.continue game": "Verder spelen",
      "home.menu.new game": "Nieuw spel beginnen",
      "home.menu.settings": "Instellingen",
      "home.menu.about this app": "Info over deze applicatie",
      "home.menu.install this app": "Installeren als applicatie",
      "settings.option.language": "Taal",
      "settings.option.show totals in table": "Toon totalen in de tabel",
      "about.title": "Info over deze applicatie",
      "about.text": `<p><strong>Fiver</strong> is een dobbelspel dat lijkt op "Yahtzee" en strategie, geluk en een vleugje competitie combineert. In deze boeiende bezigheid nemen spelers om de beurt vijf dobbelstenen in de hand met als doel verschillende combinaties te behalen en punten te verzamelen.</p><p>Elke speler krijgt drie worpen tijdens hun beurt, waardoor ze sommige dobbelstenen kunnen behouden en andere opnieuw kunnen werpen om hun resultaten te optimaliseren. De ultieme uitdaging in <strong>Fiver</strong> is om een <strong>"Fiver"</strong> te scoren, wat gebeurt wanneer alle vijf dobbelstenen dezelfde waarde tonen. Dit bereiken is een moment van pure triomf en gaat vaak gepaard met aanzienlijke puntenbeloning.</p><p>Gedurende het spel streven spelers naar combinaties zoals straten, full houses en vier-op-een-rij, elk met zijn eigen puntenwaardering. Strategie komt in het spel als spelers beslissen in welke categorie ze hun scorekaart invullen, in een poging om hun score bij elke beurt te maximaliseren.</p>`,
      "game.add player": "Speler toevoegen",
      "game.delete player": "Speler verwijderen",
      "game-table.add": "Toevoegen",
      "game-table.total": "Totaal",
      "game-table.count all": "Tel alle",
      "game-table.ones": "eenen",
      "game-table.twos": "tweeën",
      "game-table.threes": "drieën",
      "game-table.fours": "vieren",
      "game-table.fives": "vijven",
      "game-table.sixes": "zessen",
      "game-table.or more": "of meer",
      "game-table.bonus": "Bonus",
      "game-table.pair": "Paar",
      "game-table.three of a kind": "3 of a kind",
      "game-table.four of a kind": "4 of a kind",
      "game-table.full house": "Full house",
      "game-table.small street": "Kleine straat",
      "game-table.large street": "Grote straat",
      "game-table.chance": "Chance",
      "game-table.fiver": "Fiver",
      "game-table.bonus fiver": "Bonus Fiver",
      "game-table.total bottom": "Total beneden",
      "game-table.total top": "Total boven",
      "game-table.total top + bottom": "Total (boven + beneden)",
      "modal.close": "Sluiten",
      "modal.save": "Opslaan",
      "modal.yes": "Ja",
      "modal.no": "Nee",
      "modal.player": "Speler",
      "modal.no luck this time": "Geen geluk deze keer",
      "modal.chance.title {{ label }}": "Probeer je een {{ label }} te gooien?",
      "modal.chance.text {{ label }}": "<p>Selecteer de waarde voor elk van de vijf dobbelstenen, te beginnen met de <strong>{{ label }}</strong>. De score wordt automatisch bereken</p>",
      "modal.four of a kind.title {{ label }}": "Probeer je een {{ label }} te gooien?",
      "modal.four of a kind.text {{ label }}": "<p>Selecteer de waarde voor elk van de vijf dobbelstenen, te beginnen met de <strong>{{ label }}</strong>. De score wordt automatisch bereken</p>",
      "modal.full house.title {{ label }}": "You've just rolled a {{ label }}?",
      "modal.full house.text {{ label }}": "<p>Heb je de dobbelstenen gegooid en lukte het om een <strong>{{ label }}</strong>-combinatie te behalen? Met drie dobbelstenen die één waarde tonen en twee dobbelstenen die een andere waarde tonen, zoals deze?</p>",
      "modal.single.title {{ label }}": "Hoeveel {{ label }} heb je gegooid?",
      "modal.single.text {{ label }}": "<p>Kies hoeveel <strong>{{ label }}</strong> je hebt gegooid in deze worp. De score wordt automatisch berekend.</p>",
      "modal.small street.title {{ label }}": "Probeer je een {{ label }} te gooien?",
      "modal.small street.text {{ label }}": "<p>Met welke combinatie van dobbelstenen heb je gegooid om een <strong>{{ label }}</strong> te behalen?</p>",
      "modal.large street.title {{ label }}": "Probeer je een {{ label }} te gooien?",
      "modal.large street.text {{ label }}": "<p>Met welke combinatie van dobbelstenen heb je gegooid om een <strong>{{ label }}</strong> te behalen?</p>",
      "modal.three of a kind.title {{ label }}": "Probeer je een {{ label }} te gooien?",
      "modal.three of a kind.text {{ label }}": "<p>Selecteer de waarde voor elk van de vijf dobbelstenen, te beginnen met de <strong>{{ label }}</strong>. De score wordt automatisch bereken</p>",
      "modal.fiver.second": "tweede",
      "modal.fiver.another {{ label }}": "Nog een {{ label }}?",
      "modal.fiver.title": "Je bent niet te stoppen!",
      "modal.fiver.text {{ bonus }} {{ label }}": "Kies de waarden die je hebt gegooid voor jouw <strong>{{ bonus }} {{ label }}</strong>",
      "modal.new game.title": "Nieuw spel!",
      "modal.new game.text": "<p>We zijn een bestaand spel tegengekomen. Wil je stoppen en opnieuw beginnen?</p>",
      "modal.delete player.title": "Delete player",
      "modal.delete player.text {{ player }}": "<p>Ben je zeker dat je <strong>Speler {{ player }}</strong> wil verwijderen?</p>",
      "modal.end of game.title": "Game over!",
      "modal.end of game.text {{ player }}": "<p>Proficiat aan <strong>Speler {{ player }}</strong>! <br/> Wil je opnieuw spelen?</p>",
      "modal.cookies.title": "Cookies",
      "modal.cookies.text": "<p>We gebruiken <strong>Google Analytics</strong> om waardevolle inzichten te verkrijgen in de prestaties van onze website en het gedrag van gebruikers. Deze op data gebaseerde aanpak helpt ons om de gebruikerservaring te verbeteren, de inhoud te optimaliseren en geïnformeerde beslissingen te nemen om onze online aanwezigheid te verbeteren en ons publiek beter van dienst te zijn.</p>",
      "modal.cookies.accept": "Ik accepteer cookies",
      "modal.cookies.play": "Begin met spelen",
      "modal.cookies.more info": `Lees hier meer over onze "cooky policy"`,
      "cookies.title": "Cookie policy",
      "cookies.text": `
        <p>We gebruiken <strong>Google Analytics</strong> om waardevolle inzichten te verkrijgen in de prestaties van onze website en het gedrag van gebruikers. Deze op data gebaseerde aanpak helpt ons om de gebruikerservaring te verbeteren, de inhoud te optimaliseren en geïnformeerde beslissingen te nemen om onze online aanwezigheid te verbeteren en ons publiek beter van dienst te zijn.</p>
        <p><strong>Naam: </strong>_ga, _gat, _gid</p>
        <p><strong>Beschrijving: </strong>Google Analytics is een van de meest voorkomende en betrouwbare analytische oplossingen op internet, om informatie te verzamelen over hoe de website het best kan worden gebruikt en hoe de gebruikerservaring kan worden verbeterd. Met behulp van deze cookie kunnen we bijhouden hoeveel tijd u op onze website doorbrengt, welke pagina's u precies bezoekt en hoe u ons hebt gevonden. Op deze manier weten we waar bezoekers in geïnteresseerd zijn en kunnen we de website en de inhoud blijven verbeteren.</p>
        <p><strong>Duur: </strong>Deze cookie is van Google en wordt verwijderd na maximaal 2 jaar.</p>
      `,
      "modal.cookies.message": "Gelieve \"cookies\" te accepteren!",
      "app.link cookie policy": "Cookie policy"
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
