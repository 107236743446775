const Logo = ({ type = 'big' }) => {
  return (
    <div className={`logo ${type}`}>
      <span className='logo-text'>Fiver</span>
      <span className='scoreboard'>
        <span className='scoreboard-text'>Scoreboard</span>
      </span>
    </div>
  )
}

export default Logo
