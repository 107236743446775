import { useNavigate } from 'react-router-dom';
import styles from './Settings.module.scss';
import Logo from '../molecules/Logo';
import { useEffect, useState } from 'react';
import { setSetting } from '../../reducers/settings';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { APPLICATION_LANGUAGES } from '../../constants';
import { analyticsEvent, getLanguageCode } from '../../functions';

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const settings = useSelector(state => state.settings.value);

  const [language, setLanguage] = useState(APPLICATION_LANGUAGES.ENGLISH);
  const [languages] = useState([
    APPLICATION_LANGUAGES.ENGLISH,
    APPLICATION_LANGUAGES.DUTCH
  ]);

  const [showTotals, setShowTotals] = useState(false);
  const [showTotalOptions] = useState([
    'Yes',
    'No'
  ]);
  const [isSaving, setIsSaving] = useState(false);

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const handleChangeShowTotals = (event) => {
    setShowTotals(event.target.value);
  };

  const backToMenu = () => {
    navigate('/');
    analyticsEvent('click', 'back to menu', 'settings');
  }

  const onSave = () => {
    setIsSaving(true);

    if (settings['language'] !== language) {
      dispatch(setSetting({
        name: 'language',
        value: language
      }));
      analyticsEvent('click', 'save settings', 'language', language);
    }

    if (settings['showTotals'] !== showTotals) {
      dispatch(setSetting({
        name: 'showTotals',
        value: showTotals
      }));
      analyticsEvent('click', 'save settings', 'showTotals', showTotals);
    }

    setTimeout(() => {
      setIsSaving(false);
    }, 500);
  }

  useEffect(() => {
    if (languages.find(l => l === settings['language'])) {
      setLanguage(settings['language']);

      if (i18n.language !== getLanguageCode(settings['language'])) {
        i18n.changeLanguage(getLanguageCode(settings['language']))
      }
    }
  }, [settings, languages, i18n]);

  useEffect(() => {
    if (showTotalOptions.find(l => l === settings['showTotals'])) {
      setShowTotals(settings['showTotals']);
    }
  }, [settings, showTotalOptions]);

  return (
    <div>
      <button onClick={backToMenu} className='back-to-menu'>Menu</button>
      <div className={styles['logo-container']}>
        <Logo type='small' />
      </div>
      <div className={styles['settings-container']}>
        <div className='form-field'>
          <label>{t('settings.option.language')}</label>
          <div className='select-wrapper'>
            <select value={language} onChange={handleChangeLanguage}>
              {languages.map((l, key) => <option key={key}>{l}</option>)}
            </select>
          </div>
        </div>
        <div className='form-field'>
          <label>{t('settings.option.show totals in table')}</label>
          <div className='select-wrapper'>
            <select value={showTotals} onChange={handleChangeShowTotals}>
              {showTotalOptions.map((o, key) => <option key={key} value={o}>{t(o)}</option>)}
            </select>
          </div>
        </div>
        <button onClick={onSave}>
          {isSaving ? <>
            <i className="fa-solid fa-spinner fa-spin"></i>
            {t('Saving')}
          </> : t('Save')}
        </button>
      </div>
    </div>
  )
}

export default Settings
