import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';

import '../src/scss/fiver.scss';
import { Provider } from 'react-redux';
import store from './js/store';
import App from './js/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <>
      <div className='background-circle-1'><span></span></div>
      <Provider store={store}>
        <HashRouter>
          <App />
        </HashRouter>
      </Provider>
      <div className='background-circle-2'><span></span></div>
    </>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
