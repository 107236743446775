import { useDispatch, useSelector } from 'react-redux';
import { removePlayer } from '../../../reducers/players';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { analyticsEvent } from '../../../functions';

const DeletePlayerModal = ({ onClose }) => {

  const dispatch = useDispatch();
  const currentPlayer = useSelector(state => state.currentPlayer.value);

  const onDeletePlayerConfirm = () => {
    dispatch(removePlayer(currentPlayer));
    analyticsEvent('game', 'remove', 'player');
    analyticsEvent('close', 'modal', 'remove player');
    onClose();
  }

  const onContainerClick = event => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      analyticsEvent('close', 'modal', 'remove player');
      onClose();
    }
  }

  return (
    <div className='modal-container' onClick={event => onContainerClick(event)}>
      <div className='modal'>
        <div className='modal-content'>
          <button className='modal-close' onClick={onClose}>{t('modal.close')}</button>
          <span className='modal-title'>{t("modal.delete player.title")}</span>
          <Trans
            i18nKey="modal.delete player.text {{ player }}"
            values={{ player: currentPlayer + 1 }}
            components={{ p: <p />, strong: <strong /> }}
          />
          <ul className='modal-buttons'>
            <li><button onClick={onDeletePlayerConfirm}><i className="fa-solid fa-thumbs-down"></i>{t('modal.yes')}</button></li>
            <li><button onClick={onClose}><i className="fa-solid fa-thumbs-up"></i>{t('modal.no')}</button></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DeletePlayerModal;
